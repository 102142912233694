import {
    SET_CHANGE_PASSWORD_FLAG, SET_CHANGE_PASSWORD_API_FLAG, SET_PROFILE, SET_USERDETAIL, SET_ROLES, SET_WHITELABELING
} from "./action";

const initialState = {
    items: [],
    changePasswordFlag: false,
    loading: false,
    error: null,
    changePasswordAPIFlag: false,
    profile: null,
    userdetailObject: {},
    whiteLabeling: {}
};

export default function changePassword(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_WHITELABELING: {
            return {
                ...state,
                whiteLabeling: action.payload.response
            };
        }
        case SET_ROLES:{
            return {
                ...state,
                items: action.payload.roles
            };
        }
        case SET_USERDETAIL:
            return {
                ...state,
                userdetailObject: action.payload.object
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            };
        case SET_CHANGE_PASSWORD_API_FLAG:

            return {
                ...state,
                loading: false,
                changePasswordAPIFlag: action.payload.value
            };
        case SET_CHANGE_PASSWORD_FLAG:
            return {
                ...state,
                loading: false,
                changePasswordFlag: action.payload.value
            };
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}


const SERVER_ENV = window && window.location && window.location.hostname;

let ENV_APP_BASE;
let ENV_API_BASE;
let ENV_API_BASE_URL;
let ENV_USER_BASE;
let ENV_USER_BASE_URL;
let ENV_DOCLIB_BASE_URL;
let ENV_BASE_COOKIE;
let ENV_ROLLBAR_ACCESS_TOKEN;
// Replace IF conditions as per the base URL

if (SERVER_ENV.includes('localhost')) {
	ENV_APP_BASE = 'http://localhost:3000';
    ENV_API_BASE = 'http://localhost:8076/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'http://localhost:8076/api/usermodule';
    ENV_USER_BASE_URL = 'http://localhost:3001';
    ENV_DOCLIB_BASE_URL = 'http://localhost:8076/api/doclibmodule';

    ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.testing.kpininja.com/api/doclibmodule';

    ENV_BASE_COOKIE = 'localhost';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

} else if (SERVER_ENV.includes('testing.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.testing.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.testing.kpininja.com/api/doclibmodule';
    ENV_BASE_COOKIE = '.testing.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";
} else if (SERVER_ENV.includes('staging.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.staging.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.staging.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.staging.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.staging.kpininja.com';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.staging.kpininja.com/api/doclibmodule';
    ENV_BASE_COOKIE = '.staging.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "e13fd4bea8ca475895257fc386cb7ecc";
}else if (SERVER_ENV.includes('preproduction.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.preproduction.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.preproduction.kpininja.com';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.preproduction.kpininja.com/api/doclibmodule';
    ENV_BASE_COOKIE = '.preproduction.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
}  else if (SERVER_ENV.includes('.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://users.kpininja.com';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.kpininja.com/api/doclibmodule';
    ENV_BASE_COOKIE = '.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
} else if (SERVER_ENV.includes('preproduction.nehii.org')) {
    ENV_APP_BASE = 'https://ninjauniverse.preproduction.nehii.org';
    ENV_API_BASE = 'https://universegateway.api.preproduction.nehii.org/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.preproduction.nehii.org/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.preproduction.nehii.org';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.preproduction.nehii.org/api/doclibmodule';
    ENV_BASE_COOKIE = '.preproduction.nehii.org';
    ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
}else if (SERVER_ENV.includes('nehii.org')) {
    ENV_APP_BASE = 'https://ninjauniverse.nehii.org';
    ENV_API_BASE = 'https://universegateway.api.nehii.org/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.nehii.org/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.nehii.org';
    ENV_DOCLIB_BASE_URL = 'https://universegateway.api.nehii.org/api/doclibmodule';
    ENV_BASE_COOKIE = '.nehii.org';
    ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
}

export const AUTH_BASE_URL = ENV_APP_BASE;
export const API_BASE_URL = ENV_API_BASE_URL;
export const USER_BASE_API = ENV_USER_BASE + '/users';

export const USER_BASE_URL = ENV_USER_BASE_URL;
export const USER_BASE_UI = ENV_USER_BASE_URL+ "/#/user";
export const DOCLIB_BASE_URL = ENV_DOCLIB_BASE_URL + '/doclib';
export const BASE_COOKIE = ENV_BASE_COOKIE;
export const ROLLBAR_ACCESS_TOKEN = ENV_ROLLBAR_ACCESS_TOKEN;
export const ENV = {
    code_version: '1.1.1',
    environment: 'doclib',
    RootModule: 61
};

export const ACCESS_TOKEN = 'accessToken';
export const OAUTH_TOKEN = '/oauth/token';
export const NOTIFICATION_LIST = '/showNotifications';
export const NOTIFICATION_BY_ID = '/fetchNotificationDetailById';
export const COMPANY_SIGNUP = '/companyRegistration';

export const DASHBOARD_API = '/dashboard';
export const USER_PROFILE_API = '/userprofile';
export const WHITELABELING = '/whiteLabeling';

//USER PROJECT
export const GETUSERROLES = '/getUserModules';
export const USERDETAIL = '/userdetail';
export const CHECKBANNERSTATUS = '/checkBannerStatus';
export const GETPILLARSBYFY = '/getPillarsByFY';
export const GETHEADERSBYFY = '/getHeadersByFY';
export const USERDETAILSMART = '/userdetailSmart';


//Header configuration
export const NOTIFICATION_LIST_AFTERLOGIN_EVENT = '/showNotificationEvent';
export const DOWNLOAD_NOTIFICATION_ATTACHMENT = '/downLoadReactAttachment';
export const NOTIFICATION_LIST_AFTERLOGIN = '/showNotification';
export const NOTIFICATION_BY_ID_AFTERLOGIN = '/fetchNotificationDetailsById';
export const NOTIFICATION_READ = '/saveNotificationAsRead';
export const GETTIMEZONES_API = '/getTimezones';
//module backend name
export const DOCLIB_BACKEND_NAME='DOCLIB';
import React from 'react';
import ReactDOM from 'react-dom';
import '@babel/polyfill';
import { unregister } from './registerServiceWorker';
import '@fortawesome/fontawesome-pro/css/all.css'
import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Layout/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./Layout/Main', () => {
    const NextApp = require('./Layout/Main').default;
    renderApp(NextApp);
  });
}
unregister();



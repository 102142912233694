import {Redirect, Route, Switch} from 'react-router-dom';
import React, {Fragment, lazy, Suspense} from 'react';
import Loader from 'react-loaders';
import {LockScreen} from 'u-header';
import {ToastContainer} from 'react-toastify';
import Cookies from 'universal-cookie';
import {AUTH_BASE_URL, BASE_COOKIE, USER_BASE_API} from '../../Constants';

const DocLib = lazy(() => import('../../Pages'));
const NotFound = lazy(() => import('../../Pages/404'));

let defaultRedirect = '/';

const LoadingMessage = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse"/>
      </div>
    </div>
  </div>
);

const AppMain = ({userObj}) => {

  const cookies = new Cookies();

  if (!cookies.get('lock')) {
    cookies.set('lock', false,{ httpOnly: false,domain : BASE_COOKIE});
  }

  if (cookies.get('access_token')) {
    let data = userObj['data']['authorityList'];
    if (data.indexOf('DOCLIBUSER') >= 0) {
      defaultRedirect = '/doclib/docLibrary';
    } else if (data.indexOf('DOCLIBADMIN') >= 0) {
      defaultRedirect = '/doclib/docLibById';
    }  else {
      window.location.href = AUTH_BASE_URL;
    }
  } else {
    window.location.href = AUTH_BASE_URL;
  }


  return (
    <Fragment>
      <LockScreen userObj={userObj}  baseCookie={BASE_COOKIE} authUrl={AUTH_BASE_URL} userBaseAPI={USER_BASE_API}/>
      {/* Components */}

      <Suspense fallback={<LoadingMessage />}>
        <Switch>

          <Route
            path="/doclib"
            component={DocLib}
          />
          <Route path="/404">
            <NotFound />
          </Route>
          <Route path="**" render={() => (
            <Redirect to="/404"/>
          )}/>

        </Switch>
      </Suspense>

      <Route exact path="/" render={() => <Redirect to={defaultRedirect}/>}/>
      <ToastContainer/>
    </Fragment>
  );
};

export default AppMain;

import {
  BASE_COOKIE,
  GETUSERROLES,
  USER_BASE_API,
  USERDETAIL,
  USERDETAILSMART,
  AUTH_BASE_URL, WHITELABELING
} from '../Constants';
import Cookies from 'universal-cookie';
import axios from 'axios';
import {handle403Page} from 'u-header';

const cookies = new Cookies();
export const request = (options) => {

  cookies.set("isBannerStarted", false, { httpOnly: false, domain: BASE_COOKIE });

  const config = {
    headers: { 'Content-Type': 'application/json' },
    url: options['url'],
    method: options['method'],
    data: options['body']
  };

  if (cookies.get('access_token', { httpOnly: false })) {
    config['headers']['Authorization'] = '' + cookies.get('access_token', { httpOnly: false });
  }
  if (navigator.onLine) {
    return axios.request(config).then(response => {
      if (response.config.headers && response.headers.authorization) {
        cookies.set("access_token", response.headers && response.headers.authorization, {
          httpOnly: false,
          domain: BASE_COOKIE
        });
      }
      return response.data;
    }).catch((error) => {

      let response;
      if (error.response) {
        if (error.response.status === 401) {
          cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
          cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
          cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
          window.location.href = AUTH_BASE_URL + '/#/pages/login'
        } else if (error.response.status === 403) {
          handle403Page(AUTH_BASE_URL);
        } else if (error === 'Error: Network Error') {
          response = {
            status: false,
            tempMassage: "Network Error"
          };
          return response;
        } else {
          response = {
            status: false,
            tempMassage: "Bad GateWay"
          };
          return response;
        }
      } else {
        response = {
          status: false,
          tempMassage: "Bad GateWay"
        };
        return response;
      }
    })
  } else {
    let response;
    response = {
      status: false,
      tempMassage: "INTERNET DISCONNECTED"
    };
    return response;
  }
};

export const request2 = (options) => {

  cookies.set("isBannerStarted", false, { httpOnly: false, domain: BASE_COOKIE });

  const config = {
    headers: {},
    url: options['url'],
    method: options['method'],
    data: options['body']
  };

  if (cookies.get('access_token', { httpOnly: false })) {
    config['headers']['Authorization'] = '' + cookies.get('access_token', { httpOnly: false });
  }
  if (navigator.onLine) {
    return axios.request(config).then(response => {
      if (response.config.headers && response.headers.authorization) {
        cookies.set("access_token", response.headers && response.headers.authorization, {
          httpOnly: false,
          domain: BASE_COOKIE
        });
      }
      return response.data;
    }).catch((error) => {
      let response;
      if (error && error.response && error.response.status) {
        if (error && error.response && error.response.status === 401) {
          cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
          cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
          cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
          window.location.href = AUTH_BASE_URL + '/#/pages/login'
        } else if (error && error.response && error.response.status === 403) {
          handle403Page(AUTH_BASE_URL);
        } else if (error && error === 'Error: Network Error') {
          response = {
            status: false,
            tempMassage: "Network Error"
          };
          return response;
        } else {
          response = {
            status: false,
            tempMassage: "Bad Gateway"
          };
          return response;
        }
      } else {
        response = {
          status: false,
          tempMassage: "Bad Gateway"
        };
        return response;
      }
    })
  } else {
    let response;
    response = {
      status: false,
      tempMassage: "INTERNET DISCONNECTED"
    };
    return response;
  }
};

export async function getUserRoles(requestParams) {

  return await request({

    url: USER_BASE_API + GETUSERROLES,
    method: 'get',
  });
}

export async function userDetailsAPI(type) {

  if (type === 'SMART') {
    return await request({
      url: USER_BASE_API + USERDETAILSMART,
      method: 'get',
    });
  } else if (type === 'user') {
    return await request({

      url: USER_BASE_API + USERDETAIL,
      method: 'get',
    });
  }
}

export async function whitelabelingAPI() {

  return await request({

    url: USER_BASE_API + WHITELABELING,
    method: 'GET'
  })
}


import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import Loader from 'react-loaders';
import {withRouter} from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../AppMain';
import {
    setBrandColor,
    setBrandDarkColor,
    setProfileData,
    setRoles,
    setUserResponse,
    setWhiteLabeling
} from "../../reducers/action";
import {BASE_COOKIE, ENV, ROLLBAR_ACCESS_TOKEN} from "../../Constants";
import Rollbar from 'rollbar';
import {userDetailsAPI, whitelabelingAPI} from "../../Utils/UtilService";
import Cookie from 'universal-cookie';
import ThemeSwitcher from "react-css-vars";
import CacheBuster from "./CacheBuster";

const cookie = new Cookie();

class Main extends React.Component {

    constructor(props) {

        super(props);
        this.state = {

            closedSmallerSidebar: false,
            loaderState: true,
            rollbar: null
        };
    }

    componentDidMount() {
        this.getUserDetails();
        this.getWhiteLabeling();
    }

    getUserDetails = () => {
        userDetailsAPI('user').then(response => {
            if (response && response['status']) {
                this.props.dispatch(setRoles(response.data['authorityList']));
                this.props.dispatch(setUserResponse(response));
                this.props.dispatch(setProfileData(response.profile));
                this.setState({
                    userResponse: response
                })
            }
        })
    };

    getWhiteLabeling = () => {
        whitelabelingAPI()
            .then(response => {
                if (response && response['status']) {
                    this.props.dispatch(setWhiteLabeling(response));
                    this.setState({
                        whiteLabeling: response
                    })
                }
            })
    };
    setPayload = (props) => {
        let rollbar = new Rollbar({
            accessToken: ROLLBAR_ACCESS_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                ...ENV,
                CompanyId: props.rootComapnyId,
                Email: props.email,
                UserName: props.name
            }
        });
        this.setState({
            rollbar: rollbar
        })
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.profile !== null) {
            this.setPayload(nextProps.profile)
        }
    }

    LightenDarkenColor=(col, percent)=>{
        const num = parseInt(col.replace("#",""),16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;
        return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    }

    hexToRGBA = (hex, alpha = 1) => {
        hex = hex.replace('#', '');
        const r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        const g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        const b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        return `${r},${g},${b},${alpha}`;
    }

    render() {

        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        let customTheme ={};
        if(cookie.get("theme",{httpOnly: false, domain: BASE_COOKIE})){
            const {brandColor,brandHyperlinkColor,hyperLinkColor,secondaryColor, dataTableHeaderColor, dataTableHeaderFontColor, dataTableActivePageColor} = cookie.get("theme",{httpOnly: false, domain: BASE_COOKIE});
            if(brandColor){
                customTheme["primary"] = brandColor;
                customTheme["primary-dark"] = this.LightenDarkenColor(brandColor,-7);
                customTheme["brand-color"] = brandColor;
                customTheme["primary-title"] = this.hexToRGBA(this.LightenDarkenColor(brandColor, -21), 0.7);
                customTheme["primary-rgba-8"] = this.hexToRGBA(brandColor,0.8);
                customTheme["primary-rgba-9"] = this.hexToRGBA(brandColor,0.9);
                this.props.dispatch(setBrandColor(brandColor));
                this.props.dispatch(setBrandDarkColor(brandColor));
            }
            if(!brandHyperlinkColor && hyperLinkColor){
                customTheme["link-color"] = hyperLinkColor;
                customTheme["link-color-dark"] = this.LightenDarkenColor(hyperLinkColor, -7);
            } else if(brandHyperlinkColor && brandColor){
                customTheme["link-color"] = brandColor;
                customTheme["link-color-dark"] =  customTheme["primary-dark"];
            }

            if(secondaryColor){
                customTheme["secondary"] = secondaryColor;
                customTheme["secondary-dark"] = this.LightenDarkenColor(secondaryColor,-7);
            }

            if(secondaryColor){
                customTheme["secondary"] = secondaryColor;
                customTheme["secondary-dark"] = this.LightenDarkenColor(secondaryColor,-7);
            }

            if(dataTableHeaderColor){
                customTheme["data-table-header-color"] = dataTableHeaderColor;
            }

            if(dataTableHeaderFontColor){
                customTheme["data-table-font-color"] = dataTableHeaderFontColor;
            }

            if(dataTableActivePageColor){
                customTheme["data-table-page-color"] = dataTableActivePageColor;
                customTheme["data-table-page-dark-color"] = this.LightenDarkenColor(dataTableActivePageColor,-7);
            }

        }

        return (
          <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                  if (loading) return null;
                  if (!loading && !isLatestVersion) {
                      refreshCacheAndReload();
                  }
                  return(
            <ThemeSwitcher theme={customTheme}>
                <ResizeDetector
                    handleWidth
                    render={({width}) => (
                        <Fragment>
                            <div className={cx(
                                "app-container app-theme-" + colorScheme,
                                {'fixed-header': enableFixedHeader},
                                {'fixed-sidebar': enableFixedSidebar || width < 1250},
                                {'fixed-footer': enableFixedFooter},
                                {'closed-sidebar': enableClosedSidebar || width < 1250},
                                {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                                {'sidebar-mobile-open': enableMobileMenu},
                                {'body-tabs-shadow-btn': enablePageTabsAlt},
                            )}>
                                {this.state.userResponse && this.state.whiteLabeling ?
                                    <AppMain userObj={this.state.userResponse}/>
                                    :
                                    <div className="loader-container">
                                        <div className="loader-container-inner">
                                            <div className="text-center">
                                                <Loader type="ball-pulse"/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Fragment>
                    )}
                />
            </ThemeSwitcher>
                  );
              }}
          </CacheBuster>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    changePasswordFlag: state.PasswordReducer.changePasswordFlag,
    roles: state.PasswordReducer.items,
    profile: state.PasswordReducer.profile,
    userObj: state.PasswordReducer.userdetailObject,
    brandColor: state.CustomTheme.brandColor,
    brandDarkColor: state.CustomTheme.brandDarkColor
});

export default withRouter(connect(mapStateToProp)(Main));
